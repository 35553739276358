.stack-index{
    display: flex;
    min-height: 85vh;
    min-width: fit-content;
}

.stack-index-content{
    display: flex;
    width: 100%;
    justify-content: center;
}