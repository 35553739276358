.auth {
    display: flex;
    flex-direction: column;
    height: 90vh;
    padding: 30px 0;
    background-color: #333;
    color: #fff;
  }
  
  .auth-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .auth-container > p {
    margin-bottom: 30px;
    font-size: 1.5rem;
  }
  
  .sign-options {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .single-option {
    display: flex;
    padding: 9px;
    margin: 5px 0;
    border-radius: 3px;
    color: rgba(255, 255, 255, 0.8);
    background-color: #222;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(66, 42, 42, 0.19);
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .single-option:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 149, 255, 0.15), 0 6px 20px 0 rgba(0, 149, 255, 0.3);
  }
  
  .single-option > p {
    margin-left: 10px;
  }
  
  .single-option > img {
    width: 20px;
  }
  
  .auth-login {
    display: flex;
    margin: 40px 0;
    width: 300px;
  }
  
  .auth-login-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #222;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(66, 42, 42, 0.19);
    border-radius: 5px;
  }
  
  .input-field {
    display: flex;
    flex-direction: column;
  }
  
  .input-field > p {
    font-size: 1.1rem;
    margin: 10px 0;
  }
  
  .input-field > input {
    padding: 10px;
    border: 1px solid #0095ff8e;
    background-color: transparent;
    border-radius: 3px;
    outline: none;
    color: #fff;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    background-color: #0095ff;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0078d4;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .auth-login {
      width: 100%;
    }
  }
  