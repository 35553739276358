.main {
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    flex: 0.75;
  }
  
  .main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .main-top {
    display: flex;
    width: 100%;
    align-items: center;
    color: antiquewhite;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .main-top > p {
    font-weight: 400;
    font-size: 25px;
    color: rgba(255, 255, 255, 0.8);
  }
  
  button {
    padding: 10px;
    background-color: #0095ff;
    color: #fff;
    border: 2px solid #007cd446;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .main-des {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.8);
    justify-content: space-between;
    padding: 0px 0px 10px 0px;
    margin-top: 10px;
  }
  
  .main-filter {
    display: flex;
    align-items: center;
  }
  
  .main-tabs {
    display: flex;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-right: 20px;
    border-radius: 3px;
  }
  
  .main-tab {
    padding: 5px;
  }
  
  .main-filter-item {
    display: flex;
    padding: 5px;
    border: 1px solid #0095ff;
    border-radius: 3px;
    background-color: #007cd446;
    font-size: small;
    align-items: center;
    color: #007cd4;
    cursor: pointer;
  }
  
  .questions {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .question {
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  
  @media screen and (min-width: 768px) {
    .main {
      flex: 0.6;
    }
  }
  