.add-question {
    display: flex;
    width: 100%;
    background-color:#333;;
    height: 100vh;
    justify-content: center;
  }
  
  .add-question-container {
    padding: 30px 15px;
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 800px;
  }
  
  .head-title {
    display: flex;
    width: 100%;
  }
  
  .head-title > h1 {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 26px;
    color: #fff;
  }
  
  .question-container {
    display: flex;
    padding: 15px;
    background-color: #2c2c2c;
    border: 1px solid #444;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(66, 42, 42, 0.19);
  }
  
  .question-options {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .question-option {
    flex-direction: column;
    width: 100%;
  }
  
  .title {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    font-size: 0.9rem;
  }
  
  .title > h3 {
    color: #fff;
    font-weight: 500;
  }
  
  .title > small {
    color: rgba(255, 255, 255, 0.8);
  }
  
  .title > input {
    margin: 5px 0px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    outline: none;
    background-color: #383838;
    color: #fff;
  }
  
  .title > .react-quill-container {
    margin: 5px 0px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    outline: none;
    background-color: #383838;
  }
  
  .title > input::placeholder {
    color: #aaa;
  }
  
  .title > input:focus {
    border: 1px solid #00a8ff;
    box-shadow: 0 4px 8px 0 #00a8ff23, 0 6px 20px 0 #00a8ff11;
  }
  
  .react-quill {
    margin: 10px 0;
    border-radius: 10px;
    background-color: #383838;
  }
  
  .ql-editor {
    height: 200px;
    color: #fff;
  }
  
  .button {
    max-width: fit-content;
    margin: 10px 0px;
    background-color: #00a8ff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
  }
  
  .button:hover {
    background-color: #007ccf;
  }
  
  @media screen and (max-width: 768px) {
    .add-question-container {
      width: 100%;
    }
  }
  