.all-questions {
    display: flex;
    width: 100%;
    padding: 20px 0px;
    border-bottom: 1px solid #eee;
    width: 100%;
  }
  
  .all-questions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .all-questions-left {
    display: flex;
    margin-right: 30px;
  }
  
  .all-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: small;
  }
  
  .all-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .all-option > p {
    font-size: large;
  }
  
  .question-answer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .question-answer > a {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  
  .question-answer > a:hover {
    color: #ccc;
  }
  
  .question-answer > p {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 10px;
  }
  
  .question-tags {
    margin: 10px 5px;
    padding: 5px 10px;
    background-color: #3b6dd1ac;
    border-radius: 3px;
    color: #fff;
  }
  
  .author {
    display: flex;
    flex-direction: column;
    margin-left: auto;
  }
  
  .author > small {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;
  }
  
  .author-details {
    display: flex;
    align-items: center;
  }
  
  .author-details > p {
    margin-left: 5px;
    font-size: small;
    color: #fff;
  }
  