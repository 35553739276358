.main-desc {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

img {
    max-inline-size: 100%;
    block-size: auto;
    aspect-ratio: 2/1;
}

.info {
    display: flex;
    align-items: center;
    font-size: small;
}

.info>p {
    color: rgba(255, 255, 255, 0.4);
    margin: 0 10px;
}

.info>p>span {
    color: rgba(255, 255, 255, 0.8);
    margin: 0 5px;
}

.arrow {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.25);
}

.all-options>.MuiSvgIcon-root {
    color: rgba(255, 255, 255, 0.25);
    font-size: large;
    margin: 5px 0;
}

.comments {
    margin: 10px 0;
    width: 90%;
    margin-left: auto;
}

.comment {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    padding: 10px 0px;
    color: #fff;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
}

.comment>p {
    margin: 10px 0;
}

.comments>p {
    cursor: pointer;
    margin-left: -35px;
    margin-top: 20px;
    font-size: small;
    color: antiquewhite;
}

.comments>p:hover {
    color: #0095ff;
}

.comment>p>span {
    padding: 3px;
    background-color: #0151f028;
}

.pre {
    display: flex;
    width: 100%;
    overflow-x: auto;
    margin-top: 10px;
    background-color: #252525;
    border-radius: 4px;
    padding: 10px;
    color: #fff;
}

pre code {
    white-space: pre-wrap;
}

@media (max-width: 768px) {
    .main-desc {
        flex-direction: column;
        align-items: flex-start;
    }

    .info {
        margin-top: 10px;
    }

    .info>p {
        margin: 0 5px;
    }

    .comments > p{
        flex-direction: column;
        align-items: flex-start;   
    }
}